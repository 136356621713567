export const defaultColumns = [
  {
    title: '名称',
     ellipsis: true,
    dataIndex: 'name'
  },

  {
    title: '发文字号', 
    ellipsis: true,
    dataIndex: 'documentNumber'
  },

  {
    title: '文件类型',
    width: '150px',
    dataIndex: 'fileType',
    scopedSlots: { customRender: 'fileType' }
  },
  {
    title: '紧急程度',
    width: '150px',
    dataIndex: 'urgencyDegree',
    scopedSlots: { customRender: 'urgencyDegree' }
  },
  {
    title: '密级程度',
    width: '150px',
    dataIndex: 'secrecyDegree',
    scopedSlots: { customRender: 'secrecyDegree' }
  },

  {
    title: '拟稿单位/人',
    width: '150px',
    dataIndex: 'draftId',
    scopedSlots: { customRender: 'draftId' }
  },

  {
    title: '主送',
    width: '150px',
    dataIndex: 'mainIds',
    scopedSlots: { customRender: 'mainIds' }
  },

  {
    title: '抄送',
    width: '150px',
    dataIndex: 'sendIds',
    scopedSlots: { customRender: 'sendIds' }
  },

  {
    title: '拟稿日期',
    width: '150px',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: '状态',
    width: '150px',
    dataIndex: 'approvalStatus',
    scopedSlots: { customRender: 'approvalStatus' }
  },
  {
    title: '操作',
    // width: '150px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
